import React, { useState, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import InputMask from 'react-input-mask';
import connector from "../../utils/connector"

function ContactForm({ props, formStyle }) {
    var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Form/Submit";

    const [fullname, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [validator, setValidator] = useState(false);
    const [isNull, setIsNull] = useState(false);
    const [isSend, setIsSend] = useState(false);

    var data = {
        domain: window.location.protocol + '//' + window.location.hostname,
        formSystemName: "RealAccountForm",
        formValues: {
            "fullName": fullname,
            "email": email,
            "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
            "country": "Turkey",
            "countryCode": "+90",
            "approvedConditions": true,
            "subject": subject,
            "message": message
        },
        languageIsoCode: 'tr',
        siteSystemName: 'CitoTechnology',

    }

    useEffect(() => {
        if (fullname === "" || email === "" || phone === "" || subject === "" || message === "" || phone.includes("_") || validator === false) {
            setIsNull(false)
        }
        else {
            setIsNull(true)
        }
    }, [fullname, email, phone, subject, message, validator])

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }

    async function Submit() {
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.status === 1) {
                    NotificationManager.success('We will get back to you as soon as possible.', 'Your Mail Request Received!');
                    setIsSend(false);
                }
                else {
                    NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
                    setIsSend(false)
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
                        setIsSend(false)
                    }
                    else {
                        NotificationManager.success('We will get back to you as soon as possible.', 'Your Mail Request Received!');
                        setIsSend(false)
                    }
                }
                else {
                    NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
                    setIsSend(false)
                }
            })
            setFullName("");
            setEmail("");
            setPhone("");
            setSubject("");
            setMessage("");
    }

    return (
        <div className={`${formStyle}`} >
            <span>
                <NotificationContainer />
            </span>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    value={fullname}
                    onChange={event => { setFullName(event.target.value) }}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={event => { EmailChange(event.target.value) }}
                    required
                />
            </div>
            <div className="form-group">
                <InputMask
                    mask="(999) 999 99 99"
                    type="text"
                    name="phone"
                    value={phone}
                    placeholder="Phone Number"
                    onChange={event => { setPhone(event.target.value) }}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Your Subject"
                    value={subject}
                    onChange={event => { setSubject(event.target.value) }}
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    value={message}
                    onChange={event => { setMessage(event.target.value) }}
                    required
                >
                </textarea>
            </div>
            {isNull ?
                <div className="form-group">
                    <button className="btn-default btn-large" onClick={() => { Submit() }}>Submit Now</button>
                </div>
                :
                <div className="form-group">
                    <button className="btn-default btn-large" style={{background:"#777", color:"#999"}}>Submit Now</button>
                </div>
            }

        </div>
    )
}
export default ContactForm;
