const ServiceList = [
    {
        image: '/images/ftd/services/crm.jpg',
        title: 'CRM',
        description: 'We support you in Customer Relationship Management (CRM), which will help you in all your interactions with your current and potential customers.',
        link: "crm",
        detail:"<p>Customer Relationship Management (CRM) is the whole of the technology, strategies and practices that companies use to strengthen commercial relations and increase profitability, thanks to the data obtained as a result of recording all customer interactions.</p><p>We support you in Customer Relationship Management (CRM), which will help you in all your interactions with your current and potential customers.</p>"
    },
    {
        image: '/images/ftd/services/software.jpg',
        title: 'Software',
        description: 'You can use our web applications, which we have produced for you with creative solutions and easy use, for business tracking and income increase.',
        link: "software",
        detail:"<p>In our time when computer technologies come to the fore, software is one of the most important items. Software is vital, especially in the use of computers and similar devices.</p><p>You can use our web applications, which we have produced for you with creative solutions and easy use, for business tracking and income increase.</p>"
    },
    {
        image: '/images/ftd/services/network.jpg',
        title: 'Network',
        description: 'You can meet infrastructure and cabling solutions, selection of network devices and VPN services with FTD Software.',
        link: "network",
        detail:"<p>Network is a system in which data exchange takes place within seconds by connecting computers or communication tools at a distance or short distance to each other, either wired or wirelessly.</p><p>You can meet infrastructure and cabling solutions, selection of network devices and VPN services with FTD Software.</p>"
    },
    {
        image: '/images/ftd/services/santral.jpg',
        title: 'Central',
        link: "central",
        description: 'We produce solutions for the Switchboard, which you can access from a mobile phone, PC or IP phone in the office by connecting to the same line.',
        detail:"<p>The IP switchboard, which emerged as a result of technological development, is making progress on its way to being useful today. The IP switchboard, which has been produced in a way that makes people's work easier, allows communication to be carried out anywhere. This is one of the most important reasons why it is preferred.</p><p>We produce solutions for the Switchboard, which you can access from a mobile phone, PC or IP phone in the office by connecting to the same line.</p>"
    },
    {
        image: '/images/ftd/services/security.jpg',
        title: 'Cyber Security',
        description: 'As FTD Software, we provide fast, secure, customer satisfaction-based transportation services between Fortinet devices and from different brands of firewall devices to Fortigate products.',
        link: "security",
        detail:"<p>Cyber ​​security; It is the practice of protecting computers, servers, mobile devices, electronic systems, networks, and data from malicious attacks. It is also known as information technology security or electronic information security.</p><p>As FTD Software, we provide fast, secure, customer satisfaction-based transportation services between Fortinet devices and from different brands of firewall devices to Fortigate products.</p>"
    },
    {
        image: '/images/ftd/services/project.jpg',
        title: 'Project Management',
        description: 'As FTD Software, we support you in project management prepared by using information technologies in order to make the works carried out by the institution or business faster, more effective and efficient.',
        link: "project-management",
        detail:"<div><p>Conducting a situation analysis to obtain the necessary information before the preparation of the project establishes the necessary processes for resolving problems and defining the objectives of the proposed work. The main methods are:</p><ul class='list-icon'><li><i class='fa-solid fa-check mr--20'></i>Risk Management</li><li><i class='fa-solid fa-check mr--20'></i>Quality Management</li><li><i class='fa-solid fa-check mr--20'></i>Time and Cost Management</li><li><i class='fa-solid fa-check mr--20'></i>Contact Management</li><li><i class='fa-solid fa-check mr--20'></i>Supply Management</li></ul><p>can be detailed.</p><p>As FTD Software, we support you in project management prepared by using information technologies in order to make the works carried out by the institution or business faster, more effective and efficient.</p><div>"
    },
    {
        image: '/images/ftd/services/hosting.jpg',
        title: 'Web Hosting',
        description: 'We provide web design services, web domain and hosting services to our customers perfectly.',
        link: "hosting",
        detail:'<p>Websites, like real-life sites, are built on a land. The virtual land required to publish a website is called "hosting". Hosting, which is an English term, can be translated into our language as "web hosting space" or "hosting space".</p><p>We provide web design services, web domain and hosting services to our customers perfectly.</p>'
    }
]
export default ServiceList
