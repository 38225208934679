import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";
import Typed from 'react-typed';

const Logo = ({ image, image2 }) => {
    return (
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img className="logo-light" src={process.env.PUBLIC_URL + image} alt="Corporate Logo" />
                <img className="logo-dark" src={process.env.PUBLIC_URL + image2} alt="Corporate Logo" />
                <Typed style={{ fontSize: 14, marginLeft: 10, color: '#50bdc4'}} strings={['CITO TECHNOLOGY']}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop></Typed>
            </Link>
        </div>

    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
