import React from 'react';
import ContactForm from "./ContactForm";
import { FiMail, FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15" >
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5">
                    <div className="row">
                        <div className="col-lg-12 mt--10">
                            <div className="rn-contact-address mt_dec--30">
                                <div className="row">
                                    <div className="col-12">
                                        <a href="mailto:support@citotechnology.net">
                                            <div className="rn-address">
                                                <div className="icon">
                                                    <FiMail color="#E33FA1" />
                                                </div>
                                                <div className="inner">
                                                    <h4 className="title">Our Email Address</h4>
                                                    <p className="mb--30">support@citotechnology.net</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-12">
                                        <a>
                                            <div className="rn-address">
                                                <div className="icon">
                                                    <FiMapPin color="#E33FA1" />
                                                </div>
                                                <div className="inner">
                                                    <h4 className="title">Our Location</h4>
                                                    <p className="mb--30">Municipality of Stolichna, Triaditsa Region, Pozitano Str. No:9 Fl.1 A.P. Store 15 Sofia, Bulgaria</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactOne;