import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import ServiceList from '../../models/serviceList';

const ServiceThree = ({ textAlign, serviceStyle }) => {
    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={i}>
                    <a href={"/service?s=" + val.link}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <div className={`service ${serviceStyle} ${textAlign}`}>
                                <div className="inner">
                                    <div className="content">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <h4 className="title">
                                                    {val.title}
                                                </h4>
                                                <p className="description" style={{ minHeight: "150px" }} dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <div className="image">
                                                    <img src={`${val.image}`} style={{ maxWidth: "250px" }} alt="card Images" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ScrollAnimation>
                    </a>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;