import React from 'react';
import SEO from "../common/SEO";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactOne from "../elements/contact/ContactOne";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

const Contact = () => {
    return (
        <div className="page-wrapper">
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
            <SEO title="Contact | CITO | TECHNOLOGY AND INTERNET SERVICES" />
            <BreadcrumbOne
                title="Contact"
                rootUrl="/"
                parentUrl="Home"
                currentUrl="Contact"
            />
            <div className="main-content">
                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                    
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Contact"
                                    title="Let's Contact Us !"
                                    description="You can contact us by filling out the form. Our team will reach you as soon as possible."
                                />
                            </div>
                        </div>
                        <ContactOne />
                    </div>
                </div>
            </div>
            <FooterTwo />
            <Copyright />
        </div>
    )
}
export default Contact;