import React from 'react';
import ServiceList from '../../models/serviceList';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="/">Homepage</a></li>

            <li><a href="/about-us">About Us</a></li>

            <li className="has-droupdown"><a href="/services">Services</a>
                <ul className="submenu">
                    {
                        ServiceList.map(item => {
                            return (
                                <li key={item.title}><a href={"/service?s=" + item.link}>{item.title}</a></li>
                            )
                        })
                    }
                </ul>
            </li>

            <li><a href="/contact">Contact</a></li>
        </ul>
    )
}
export default Nav;
