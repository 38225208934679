import React from 'react'
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';

const Error = () => {
    return (
        <div className="page-wrapper">
            <SEO title="404 Not Found | CITO | TECHNOLOGY AND INTERNET SERVICES" />
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
            <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-lg-12">
                            <div className="error-inner">
                                <h1 className="theme-gradient">404</h1>
                                <h2 className="title">Something’s not right.</h2>
                                <p className="description">The page you are trying to reach is not live or the link address may have been changed. You can click the button to return to the homepage.</p>
                                <div className="view-more-button">
                                    <a className="btn-default" href="/">Go Back Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo/>
            <Copyright />
        </div>
    )
}

export default Error;