import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'
import Homepage from "./pages/Homepage";
import Service from "./pages/Service";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={"/"} exact component={Homepage}/>
                    <Route path={"/service"} exact component={Service}/>
                    <Route path={"/services"} exact component={Services}/>
                    <Route path={"/about-us"} exact component={AboutUs}/>
                    <Route path={"/contact"} exact component={Contact}/>
                    <Route component={Error}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
