import React from 'react';
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import ScrollTop from "./ScrollTop";

const FooterTwo = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">CITO TECHNOLOGY</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">Technology and Internet Services</h6>
                                        {/* <ul className="social-icon social-default justify-content-start">
                                            <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                            <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                            <li><Link to="instagram.com"><FiInstagram /></Link></li>
                                            <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12"></div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">Contact Us</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><a>Municipality of Stolichna, Triaditsa Region, Pozitano Str. No:9 Fl.1 A.P. Store 15 Sofia, Bulgaria</a></li>
                                            <li><a href={"mailto: support@citotechnology.net"}> support@citotechnology.net</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
