import React from 'react'

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="copyright-left text-center text-md-left">
                            <p className="copyright-text">Copyright © CITO TECHNOLOGY {new Date().getFullYear()} - All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;